
import React,  {Alert,Component} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { isExpired, decodeToken }from "react-jwt";
import { Button,Form,Modal} from 'react-bootstrap';
import Loader from "react-js-loader";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Consent extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      lng:'',
      serviceprovision:true,
      acceptexperience:false,
      textacceptexperience:"",
      acceptmarketing:false,
      textacceptmarketing:"",
      acceptshare:false,
      textacceptshare:"",
      acceptupload:true,
      loadingdata:true,
      };

        
  }
    
    componentDidMount() {
        
    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let token = sessionStorage.getItem('tokenunsaid');

    if(token === '' || token === 'Undefined' || token === null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        lng:'',
      })
      return;
    }

    const myDecodedToken = decodeToken(token);
    
    if(myDecodedToken == null){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ 
        tokenvalue: '',
        connected:false,
        admin: '',
        manager: '',
        user: '',
        lng:'',
      })
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    fetch(wbslinkload + `getuser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

        
        this.setState({
          lng: JSON.stringify(data[0].langue).replace(/"/g, ""),
        })

        if(parseInt(JSON.stringify(data[0].serviceprovision).replace(/"/g, "")) == 0){

          this.setState({
            serviceprovision: false,
          })

        }


        if(parseInt(JSON.stringify(data[0].acceptexperience).replace(/"/g, "")) == 1){

          this.setState({
            acceptexperience: true,
          })

          if(JSON.stringify(data[0].langue).replace(/"/g, "") == "FR"){

            this.setState({
                textacceptexperience:'Oui, j\'accepte',
              })
  
          }else{
  
            this.setState({
              textacceptexperience:'Yes, I accept',
            })
  
          }

        }


        if(parseInt(JSON.stringify(data[0].acceptmarketing).replace(/"/g, "")) == 1){

          this.setState({
            acceptmarketing: true,
          })

          if(JSON.stringify(data[0].langue).replace(/"/g, "") == "FR"){

            this.setState({
                textacceptmarketing:'Oui, j\'accepte',
              })
  
          }else{
  
            this.setState({
              textacceptmarketing:'Yes, I accept',
            })
  
          }

        }

        if(parseInt(JSON.stringify(data[0].acceptshare).replace(/"/g, "")) == 1){

          this.setState({
            acceptshare: false,
          })

          if(JSON.stringify(data[0].langue).replace(/"/g, "") == "FR"){

            this.setState({
                textacceptshare:'Oui, j\'accepte',
              })
  
          }else{
  
            this.setState({
              textacceptshare:'Yes, I accept',
            })
  
          }

        }

        if(parseInt(JSON.stringify(data[0].acceptupload).replace(/"/g, "")) == 0){

          this.setState({
            acceptupload: false,
          })

        }

        if(JSON.stringify(data[0].langue).replace(/"/g, "") == "FR"){

            document.title = "Consentment";

        }else{

          document.title = "Consent";

        }

        if(JSON.stringify(data[0].typesubscription).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){
          document.location.href = "/Buycredit";
        }

        this.setState({
          loadingdata:false
        })

        window.scrollTo(0,0)
        

    })

    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  
        
  };

  acceptexperience(e){

    var acceptexperience = document.getElementById("acceptexperience").checked;

    this.setState({
      acceptexperience: e.target.checked,
    })

    if(e.target.checked == false){

      if(this.state.lng == "FR"){

        this.setState({
            textacceptexperience:'Non, je refuse'
          })
  
      }else{
        this.setState({
          textacceptexperience:'No, I decline'
        })
      }

    }else{

      if(this.state.lng == "FR"){

        this.setState({
            textacceptexperience:'Oui, j\'accepte'
          })
  
      }else{
        this.setState({
          textacceptexperience:'Yes, I accept'
        })
      }

    }


  }

  acceptmarketing(e){

    var acceptmarketing = document.getElementById("acceptmarketing").checked;

    this.setState({
      acceptmarketing: e.target.checked,
    })

    if(e.target.checked == false){

      if(this.state.lng == "FR"){

        this.setState({
            textacceptmarketing:'Non, je refuse'
          })
  
      }else{
        this.setState({
          textacceptmarketing:'No, I decline'
        })
      }

    }else{

      if(this.state.lng == "FR"){

        this.setState({
          textacceptmarketing:'Oui, j\'accepte'
          })
  
      }else{
        this.setState({
          textacceptmarketing:'Yes, I accept'
        })
      }

    }

  }

  acceptshare(e){

    var acceptshare = document.getElementById("acceptshare").checked;

    this.setState({
      acceptshare: e.target.checked,
    })

    if(e.target.checked == false){

      if(this.state.lng == "FR"){

        this.setState({
            textacceptshare:'Non, je refuse'
          })
  
      }else{
        this.setState({
          textacceptshare:'No, I decline'
        })
      }

    }else{

      if(this.state.lng == "FR"){

        this.setState({
          textacceptshare:'Oui, j\'accepte'
          })
  
      }else{
        this.setState({
          textacceptshare:'Yes, I accept'
        })
      }

    }

    
  }

  validatergpd(){

    var acceptexperience = document.getElementById("acceptexperience").checked;
    var acceptmarketing = document.getElementById("acceptmarketing").checked;
    var acceptshare = document.getElementById("acceptshare").checked;

    if(Number(this.state.serviceprovision) === 0){

        if(this.state.lng == "FR"){

          alert("Veuillez accepter la fourniture du Service de la section 1.")
          return;
    
        }else{

          alert("Please accept Service Provision in section 1.")
          return;

        }

    }

    if(Number(this.state.acceptupload) === 0){

      if(this.state.lng == "FR"){

        alert("Veuillez accepter le consentement de la section 3.")
        return;
  
      }else{

        alert("Please accept consentement in section 3.")
        return;

      }

    }
   
    //alert(Number(acceptshare))

    //alert("SERVICE " + Number(this.state.serviceprovision));
    //alert("EXPERIENCE " + Number(acceptexperience));
    //alert("MARKETING " + Number(acceptmarketing));
    //alert("SHARE " + Number(acceptshare));
    //alert("UPLOAD " + Number(this.state.acceptupload));


    let token = sessionStorage.getItem('tokenunsaid');
      
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("serviceprovision",Number(this.state.serviceprovision));
    formData.append("acceptexperience",Number(acceptexperience));
    formData.append("acceptmarketing",Number(acceptmarketing));
    formData.append("acceptshare",Number(acceptshare));
    formData.append("acceptupload",Number(this.state.acceptupload));

    fetch(this.state.wbslink + `updatergpd`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

      })

      .then(res => {

        errocode = res.status;

        if (!res.ok) {
            
            return res.json().then(json => { throw json; });

        }

        return res.json();

      })
      .then(data => {

        if(this.state.lng === "FR"){

          alert("Modification bien éfféctuée.")
    
        }else{
    
          alert("Update successful.")
          
        }
      
      })

      .catch(err => {

        if(errocode == 401 || errocode == 0){
          
          sessionStorage.removeItem("tokenunsaid");
          document.location.href = "/login";

          return

        }

        alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

        this.setState({
          loadershow: false
        })

      });  

  }

  clearvalidation(){

    window.location.reload();

  }
   
    
  render() {

          return (
              
                <div style={{marginLeft:"2%",marginRight:"2%"}}>

                {this.state.loadingdata === true ?
                      
                  <div className='centercontent'>

                    <Loader type="spinner-cub" bgColor={'#2A83E8'} color={'#2A83E8'} size={100} />

                  </div>
                  

                :

                  <div>

                    <p style={{marginTop:"80px", color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "Plateforme Unsaid - Protection de la Vie Privée":"Unsaid Platform - Privacy Protection"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "Afin de respecter le Règlement Général sur la Protection des Données (RGPD) et de garantir la confidentialité de toutes les personnes identifiables dans les enregistrements que vous téléchargez, nous vous demandons de bien vouloir lire attentivement ce formulaire et de donner votre consentement aux conditions suivantes.":"To comply with the General Data Protection Regulation (GDPR) and protect the privacy of all identifiable persons in your uploaded recordings, please read this form carefully and provide consent to the following terms."}</p>
                    <hr className="lineseparator" ></hr> 
                    {/*SECTION 1 */}             
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "Section 1 : Consentement de l'Utilisateur Principal":"Section 1: Main User Consent"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "En tant qu'utilisateur de la plateforme Unsaid, je consens au traitement de mes données personnelles, y compris mes informations d'identification, mes données d'utilisation, et mes enregistrements audio/vidéo, dans le respect des finalités suivantes :":"As an Unsaid platform user, I consent to the processing of my personal data, including identifying information, usage data, and audio/video recordings, for the following purposes:"}</p>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? "1. Fourniture du Service":"1. Service Provision"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Mes données seront utilisées pour permettre et améliorer l'utilisation de la plateforme Unsaid conformément aux Conditions d'Utilisation.":"My data will be used to enable and improve the Unsaid platform in line with the Terms of Use."}</p>
                    <div className="form-group" style={{marginLeft:"1.5%",marginTop:"-10px"}}>
                      <Form.Group>
                        <Form.Check type={"checkbox"}>
                          <Form.Check.Label>{this.state.lng=== "FR"? "Obligatoire":"Mandatory"}</Form.Check.Label>
                            <Form.Check.Input
                              type={"checkbox"}
                              defaultChecked={this.state.serviceprovision}
                              onClick={(e) => {
                                this.setState({ serviceprovision: e.target.checked });
                              }}
                            />
                        </Form.Check>
                      </Form.Group>
                    </div>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%", marginTop:"20px"}}>{this.state.lng=== "FR"? "2. Personnalisation de mon Expérience":"2. Experience Personalization"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Mes données pourront être analysées afin de me proposer une expérience personnalisée.":"My data may be analyzed to offer a personalized experience."}</p>
                    <div className="form-group" style={{marginLeft:"1.5%",marginTop:"-10px"}}>
                      <Form.Check
                        type="switch"
                        id="acceptexperience"
                        label={this.state.textacceptexperience}
                        defaultChecked={this.state.acceptexperience}
                      onClick={(e) => this.acceptexperience(e)}
                      />
                    </div>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%", marginTop:"20px"}}>{this.state.lng=== "FR"? "3. Marketing et Communication":"3. Marketing and Communication"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Je consens à recevoir des offres et actualités concernant les services Unsaid par e-mail ou SMS.":"I consent to receive offers and updates from Unsaid via email or SMS."}</p>
                    <div className="form-group" style={{marginLeft:"1.5%",marginTop:"-10px"}}>
                      <Form.Check
                        type="switch"
                        id="acceptmarketing"
                        label={this.state.textacceptmarketing}
                        defaultChecked={this.state.acceptmarketing}
                      onClick={(e) => this.acceptmarketing(e)}
                      />
                    </div>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%", marginTop:"20px"}}>{this.state.lng=== "FR"? "4. Partage de Données avec les Partenaires":"4. Data Sharing with Partners"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Je consens à ce que mes données puissent être partagées avec des partenaires de confiance pour des offres ou services complémentaires.":"I consent to my data being shared with trusted partners for complementary offers or services."}</p>
                    <div className="form-group" style={{marginLeft:"1.5%",marginTop:"-10px"}}>
                      <Form.Check
                        type="switch"
                        id="acceptshare"
                        label={this.state.textacceptshare}
                        defaultChecked={this.state.acceptshare}
                      onClick={(e) => this.acceptshare(e)}
                      />
                    </div>
                    
                    {/*SECTION 2 */} 
                    <hr className="lineseparator" ></hr>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "Section 2 : Engagement pour le Consentement des Tiers":"Section 2: Third-Party Consent Commitment"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "En tant qu'utilisateur de la plateforme Unsaid, je reconnais que certains enregistrements audio/vidéo que je télécharge peuvent inclure des voix, des images ou d'autres informations identifiables de tiers. Je m'engage à respecter les règles suivantes :":"As a user of the Unsaid platform, I recognize that some uploaded audio/video recordings may include voices, images, or other identifiable information of third parties. I agree to adhere to the following rules:"}</p>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? "1. Obligation de Consentement des Participants Identifiables":"1. Obligation to Obtain Consent from Identifiable Participants"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Je m'engage à obtenir le consentement explicite de chaque personne identifiable dans les enregistrements audio ou vidéo que je télécharge sur la plateforme Unsaid. Ce consentement couvre l'utilisation de leur voix, image et toute autre information personnelle, et leur donne le droit de demander la suppression de ces données.":"I commit to obtaining explicit consent from each identifiable person in any audio or video recording uploaded to the Unsaid platform. This consent must cover the use of their voice, image, and other personal information, with the right to request deletion of this data."}</p>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? "2. Documentation du Consentement":"1. Obligation to Obtain Consent from Identifiable Participants"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Je m'engage à documenter le consentement de chaque personne identifiable dans l'enregistrement. Cette documentation peut inclure:":"I agree to document each identifiable person's consent in the recording. This documentation may include:"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "• Un enregistrement vocal du consentement de chaque personne directement dans le fichier audio ou vidéo.":"• A recorded verbal consent within the audio or video file."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "•	Un formulaire de consentement écrit et signé que je conserverai en tant que preuve de consentement, si demandé.":"• A written and signed consent form that I retain as proof, if required."}</p>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left", marginLeft:"1%", marginTop:"20px"}}>{this.state.lng=== "FR"? "3. Confirmation de Consentement Avant Téléversement":"3. Confirmation of Consent Prior to Upload"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1.5%",marginTop:"-10px"}}>{this.state.lng=== "FR"? "Je certifie que tous les enregistrements que je télécharge sur Unsaid comportent le consentement requis de toutes les personnes identifiables.":"I confirm that all uploaded recordings have the necessary consent from all identifiable persons as required by Unsaid and GDPR."}</p>
                    <div className="form-group" style={{marginTop:"0px"}}>
                      <Form.Group>
                        <Form.Check type={"checkbox"}>
                          <Form.Check.Label>{this.state.lng=== "FR"? "Je certifie d'avoir lu dans la section 2 ci dessus les parties 1, 2, 3 et avoir confirmé le consentement explicite de chaque personne identifiable dans l'enregistrement conformément aux exigences de Unsaid et du RGPD.":"I certify that I have read Section 2 above, Chapters 1, 2, and 3, and have confirmed the explicit consent of each identifiable person in the recording in accordance with the requirements of Unsaid and the GDPR."}</Form.Check.Label>
                            <Form.Check.Input
                              type={"checkbox"}
                              defaultChecked={this.state.acceptupload}
                              onClick={(e) => {
                                this.setState({ acceptupload: e.target.checked });
                              }}
                            />
                        </Form.Check>
                      </Form.Group>
                    </div>
                    
                    {/*SECTION 3 */} 
                    <hr className="lineseparator" ></hr>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "Section 3 : Vos Droits et Sécurité des Données":"Section 3: Your Rights and Data Security"}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "Conformément au RGPD, vous disposez des droits suivants :":"In accordance with GDPR, you have the following rights:"}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? <span style={{fontWeight:"bold"}}>• Accès : </span>:<span style={{fontWeight:"bold"}}>• Access : </span>}{this.state.lng=== "FR"?"consulter les données que nous détenons sur vous.":"View the data we hold about you."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? <span style={{fontWeight:"bold"}}>• Rectification : </span>:<span style={{fontWeight:"bold"}}>• Rectification : </span>}{this.state.lng=== "FR"?"corriger des informations incorrectes.":"Correct inaccurate information."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? <span style={{fontWeight:"bold"}}>• Effacement : </span>:<span style={{fontWeight:"bold"}}>• Erasure : </span>}{this.state.lng=== "FR"?"demander la suppression de vos données personnelles.":"Request deletion of your personal data."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? <span style={{fontWeight:"bold"}}>• Opposition et Limitation : </span>:<span style={{fontWeight:"bold"}}>• Objection and Restriction : </span>}{this.state.lng=== "FR"?"limiter ou refuser certains traitements.":"Limit or refuse certain data processing."}</p>
                    <p style={{color:"black", textAlign:"left", marginLeft:"1%"}}>{this.state.lng=== "FR"? <span style={{fontWeight:"bold"}}>• Opposition et Portabilité : </span>:<span style={{fontWeight:"bold"}}>• Portability : </span>}{this.state.lng=== "FR"?"recevoir une copie de vos données.":"Receive a copy of your data."}</p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "Pour toute question ou pour exercer vos droits, vous pouvez nous contacter à l'adresse suivante: ":"For questions or to exercise your rights, contact us at: "} <a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:contact@unsaid.fr"}>contact@unsaid.fr</a></p>
                    <p style={{color:"black",textAlign:"left"}}>{this.state.lng=== "FR"? "En cliquant sur \"Accepter\", vous consentez au traitement de vos données comme décrit ci-dessus et vous vous engagez à obtenir le consentement des tiers dans vos enregistrements conformément aux règles du RGPD.":"By clicking \"Accept,\" you consent to the data processing as described above and commit to obtaining third-party consent in your recordings in compliance with GDPR."} </p>
                    
                    <div className=' d-flex flex-row align-items-center justify-content-center text-white' style={{marginTop:"40px",marginBottom:"40px"}}>
                                              
                          <Button className="btn"style={{ width:'100px'}} onClick={ () => this.clearvalidation()} variant="secondary">{this.state.lng ==='FR'? 'Refuser': 'Decline'}</Button>
                          <Button className="btn" style={{ marginLeft:"80px", width:'100px'}} onClick={() => this.validatergpd()} variant="primary">{this.state.lng ==='FR'? 'Accepter': 'Accept'}</Button>

                    </div>
                    {/*REMINDER*/}
                    <hr className="lineseparator" ></hr>
                    <p style={{color:"black",fontWeight:"bold", textAlign:"left"}}>{this.state.lng=== "FR"? "Rappel : Respect du RGPD et Consentement des Tiers":"Reminder: GDPR Compliance and Third-Party Consent"}</p>
                    <p style={{color:"black",textAlign:"left", marginBottom:"80px"}}>{this.state.lng=== "FR"? "Merci de respecter les droits de confidentialité des autres personnes dans vos enregistrements. En respectant ces règles, vous contribuez à garantir une utilisation éthique et respectueuse de la plateforme Unsaid.":"Thank you for respecting the privacy rights of others in your recordings. By following these guidelines, you contribute to an ethical and respectful use of the Unsaid platform."}</p>
                         

                    
                    
                  </div>

                }
                    
     
                </div>
          );
        }
    
}

export default Consent;
