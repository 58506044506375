import logo from './logo.svg';
import './App.css';
//import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import React, { useEffect, Component} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link,Redirect,HashRouter } from 'react-router-dom';
import { Nav,Navbar,Button,Form,Container,Row,Col,Dropdown,NavDropdown} from 'react-bootstrap';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import Utilisateurs from './components/Utilisateurs';
import Fichiersaudio from './components/Fichiersaudio';
import KPIGlobale from './components/KPIGlobale';
import Inscription from './components/Inscription';
import Profil from './components/Profil';
import Deconnexion from './components/Deconnexion';
import Connexion from './components/Connexion';
import Login from './components/Login';
import Menu from './components/Menu';
import Audio from './components/Audio';
import Transcription from './components/Transcription';
import Trash from './components/Trash';
import Jargon from './components/Jargon';
import Globalsentiment from './components/Globalsentiment';
import Globalsentimentville from './components/Globalsentimentville';
import Map from './components/Map';
import Society from './components/Society';
import Entreprise from './components/Entreprise';
import Telemarketing from './components/Telemarketing';
import Summarization from './components/Summarization';
import Dataextraction from './components/Dataextraction';
import Keyword from './components/Keyword';
import Dashboard from './components/Dashboard';
import Buycredit from './components/Buycredit';
import Listpayment from './components/Listpayment';
import Consent from './components/Consent';
import Confidentiality from './components/Confidentiality';
import Report from './components/Report';
import Sucess from './components/Sucess';
import Abort from './components/Abort';
import Activate from './components/Activate';
import Resetpassword from './components/Resetpassword';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile,isIE, isSafari} from 'react-device-detect';
import packageJson from '../package.json';



class App extends Component{
    
      constructor(props) {
      super(props);
      this.state = {
            show: false,
            setShow: false,
            tokenvalue:'',
            reload : false,
            username:'',
            tokenid:'',
          };
          
    }

      
      componentDidMount() {

            {/* 
            if(isIE === true || isSafari === true){
                  alert('Votre navigateur n\'est pas compatible.');
            }
            */}

            let lnguser = sessionStorage.getItem('langueuser');

            if(lnguser === "FR"){
                  document.title = "Bienvenue chez UNSAID";
            }else{
                  document.title = "Welcome to UNSAID";
            }

            
           
      };

      componentWillMount() {

            caches.keys().then((names) => {
                  names.forEach((name) => {
                      caches.delete(name);
                  });
              });

            sessionStorage.setItem('wbs', packageJson.wbslink);

            //alert(window.location.pathname);
                  
      }
      
      showDropdown = (e)=>{
            this.setState({ show: true, setShow:true})
            
          }
        
          hideDropdown = e => {
            this.setState({ show: false, setShow:false})
        }
     
      
    render() {

        return (


            <div>

                  <Router>
                  
                        
                  <div style={{position:"fixed", width:"100%", top:0, zIndex: '1000'}}>

                        <Menu />
                  
                  </div>
                       
                        
                  {/*<Sidebar />*/}
                  
                  
                  <Switch>
                        
                        <Route  path="/" exact component={Login}></Route>
                        <Route  path="/Audio" exact component={Audio} ></Route>
                        <Route  path="/Transcription" exact component={Transcription} ></Route>
                        <Route  path="/Trash" exact component={Trash} title="Trash"></Route>
                        <Route  path="/Utilisateurs" exact component={Utilisateurs} ></Route>
                        <Route  path="/Fichiersaudio" exact component={Fichiersaudio} title="Fichiersaudio"></Route>
                        <Route  path="/KPIGlobale" exact component={KPIGlobale} title="KPIGlobale"></Route>
                        <Route  path="/Inscription" exact component={Inscription} title="Inscription"></Route>
                        <Route  path="/Login" exact component={Login} title="Login"></Route>
                        <Route  path="/Profil" exact component={Profil} title="Profil"></Route>
                        <Route  path="/Connexion" exact component={Connexion} title="Connexion"></Route>
                        <Route  path="/Deconnexion" exact component={Deconnexion} title="Deconnexion"></Route>
                        <Route  path="/Jargon" exact component={Jargon} title="Jargon"></Route>
                        <Route  path="/Globalsentiment" exact component={Globalsentiment} title="Globalsentiment"></Route>
                        <Route  path="/Globalsentimentville" exact component={Globalsentimentville} title="Globalsentimentville"></Route>
                        <Route  path="/Map" exact component={Map} title="Map"></Route>
                        <Route  path="/Society" exact component={Society} title="Society"></Route>
                        <Route  path="/Entreprise" exact component={Entreprise} title="Entreprise"></Route>
                        <Route  path="/Telemarketing" exact component={Telemarketing} title="Telemarketing"></Route>
                        <Route  path="/Summarization" exact component={Summarization} title="Summarization"></Route>
                        <Route  path="/Keyword" exact component={Keyword} title="Keyword"></Route>
                        <Route  path="/Dataextraction" exact component={Dataextraction} title="Dataextraction"></Route>
                        <Route  path="/Dashboard" exact component={Dashboard} title="Dashboard"></Route>
                        <Route  path="/Buycredit" exact component={Buycredit} title="Buycredit"></Route>
                        <Route  path="/Listpayment" exact component={Listpayment} title="Listpayment"></Route>
                        <Route  path="/Consent" exact component={Consent} title="Consent"></Route>
                        <Route  path="/Confidentiality" exact component={Confidentiality} title="Confidentiality"></Route>
                        <Route  path="/meetingreport" exact component={Report} title="Report"></Route>
                        <Route  path="/Sucess/:datapaiement" exact component={Sucess} title="Sucess"></Route>                      
                        <Route  path="/Abort/:datapaiement" exact component={Abort} title="Abort"></Route>                      
                        <Route  path="/Activate/:datatoken" exact component={Activate} title="Activate"></Route>                      
                        <Route  path="/Resetpassword/:datatoken" exact component={Resetpassword} title="Resetpassword"></Route>                      

                  </Switch>

            </Router>
            
            

            </div>

            

            );  
  
      }

}

export default App;






















